.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: none;
  color: #97999a;
  border: none;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  /* width: 300px; */
  outline: none;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background: none !important;
  border: none !important;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .country-list .country-name {
  margin-right: 6px;
  color: black;
}
.react-tel-input .selected-flag .arrow {
  border-right: 3px solid transparent;
  /* border-top: 4px solid white; */
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: none !important;
  border-radius: 3px 0 0 0;
}
.react-tel-input .country-list {
  width: 253px;
  max-height: 192px;
}
.react-tel-input .selected-flag .arrow {
  left: 25px;
}
