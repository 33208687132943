.bideo__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translatey(-50%) translatex(-50%);
  transition: var(--transition);
}

.bideo__play--icon {
  width: 6.5rem;
  height: 6.5rem;
  background: var(--color-background);
  color: var(--color-hover);
  border-radius: 50%;
  margin-top: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: animate 2s linear infinite;
}

.bideo__play--icon:hover {
  color: var(--color-white);
  background: var(--color-hover);
}

.bideo__play--icon:hover {
  transform: scale(1.08);
}

@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 rgba(201, 127, 95, 0.7);
  }

  40% {
    box-shadow: 0 0 0 50px rgba(201, 127, 95, 0);
  }

  80% {
    box-shadow: 0 0 0 50px rgba(201, 127, 95, 0);
  }

  100% {
    box-shadow: 0 0 0 rgba(201, 127, 95, 0);
  }
}

.aboutOne {
  border-radius: 2rem;
  margin-top: 2.375rem;
  box-shadow: 55px 28px 5px #fa4a4a;
}

.aboutTwo {
  border-radius: 0.4375rem;
  width: 62%;
  margin-left: 34rem;
}

.about__content--info {
  gap: 1rem;
  padding: 2rem 0;
}

@media only screen and (max-width: 575px) {
  .about__content--details {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 2.5rem;
  }
  .about__thumbnail--list.two {
    position: absolute;

    top: 229px;
    right: 0;
  }
}

.about__video--icon {
  width: 5rem;
  height: 5rem;
  background: var(--color-background);
  box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.08);
  color: var(--color-hover);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.about__video--icon:hover {
  background: var(--color-hover);
  color: var(--color-white);
}

@media (max-width: 767px) {
  .aprroaches {
    margin-top: 17px;
  }
  .bideo__play--icon {
    width: 4.5rem;
    height: 4.5rem;
    margin-top: 17px;
  }
}
