.hero-section {
  background: #000000b3 url("../../../..//src/assets/1.jpg");
  background-attachment: fixed;
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  /* height: 40vh; */
  scroll-behavior: smooth;
  text-transform: capitalize;
  width: 100%;
  /* height: 40vh; */
  margin-top: 41px;
  margin-bottom: 25px;
}
