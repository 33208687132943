button.contact__form--btn.solid__btn {
  border: none;
  border-radius: 4.25rem;
  color: #fff;
  cursor: pointer;
  background: #d52e32;
  display: block;
  font-size: 90%;
  height: 45px;
  font-size: 17px;
  left: 12%;
  margin: 0.5rem 0 1rem;
  padding: 0.5rem 0.75rem;
  position: absolute;
  transform: translate(-50%);
  width: 24%;
}
button.solid__btn.text-white {
  border: none;
  border-radius: 4.25rem;
  color: #fff;
  cursor: pointer;
  background: #d52e32;
  margin-top: 22px;
  display: block;
  font-size: 90%;
  height: 45px;
  font-size: 17px;
  /* border: 17px; */
  left: 12%;
  margin: 0.5rem 0 1rem;
  padding: 0.5rem 0.75rem;
  position: absolute;
  transform: translate(-50%);
  width: 20%;
}
