.subscribe-section {
  background-color: rgba(243, 243, 243, 1);
}

.btn-orange {
  background-color: rgb(213 46 50);
  color: #fff;
  border: none;
  font-size: 1rem;
}
