.ltn__header-top-area {
  border-bottom: 1px solid;
}

.ltn__top-bar-menu > ul > li {
  display: inline-block;
  margin: 0 30px 0 0;
  font-weight: var(--font-body-weight);
}

.ltn__top-bar-menu > ul > li > a > i {
  font-size: 14px;
  font-weight: var(--font-body-weight);
  margin-right: 4px;
  color: #d42e32;
}

.ltn__top-bar-menu > ul > li > a i:hover {
  color: #d42e32;
}

.ltn__social-media ul li {
  list-style: none;
  display: inline-block;
  margin: 0 15px 0 0;
}

.ltn__social-media > ul > li > a > i {
  color: white;
  font-size: var(--font-body-size);
  font-weight: var(--font-heading-weight);
}

.ltn__social-media > ul > li > a > i:hover {
  color: #d42e32;
}

@media only screen and (max-width: 767px) {
  .ltn__header-top-area {
    display: none;
  }
}
