.popular__featured--section {
  /* background: url(../img/other/bg-full-4.png); */
  padding-bottom: 14rem;
  background-position: center center;
  background-size: cover;
}
@media only screen and (min-width: 992px) {
  .popular__featured--section {
    padding-bottom: 16rem;
  }
}

.popular__featured--column5 {
  padding-bottom: 5rem;
}

.popular__featured--card:hover .popular__featured--badge {
  opacity: 1;
}
.popular__featured--card:hover .popular__featured--img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.popular__featured--card:hover .popular__featured--subtitle span {
  transform: rotate(45deg);
}

.popular__featured--img {
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.popular__featured--link {
  overflow: hidden;
  border-radius: 1rem;
  position: relative;
}
.popular__featured--link::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.62));
  transition: 0.3s;
  z-index: 9;
}

.popular__featured--content {
  position: absolute;
  bottom: 3.5rem;
  left: 1.5rem;
  cursor: pointer;
  z-index: 9;
}

.popular__featured--badge {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 9;
}

.popular__featured--thumbnail {
  line-height: 1;
}

.popular__featured--title {
  color: var(--color-white);
  margin-bottom: 1.5rem;
}

.popular__featured--subtitle {
  color: var(--color-white);
  text-transform: uppercase;
}
.popular__featured--subtitle span {
  color: var(--color-hover);
  margin-left: 1rem;
}

.popular__featured--badge {
  width: 4.5rem;
  height: 4.5rem;
  background: var(--color-hover);
  color: var(--color-white);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.featured__support--desc {
  font-size: 1.8rem;
  color: var(--color-white);
  font-weight: 600;
  margin-top: 3rem;
}
@media only screen and (min-width: 992px) {
  .featured__support--desc {
    margin-top: 5rem;
  }
}
.featured__support--desc a {
  color: var(--color-hover);
}
.featured__support--desc a:hover {
  text-decoration: underline;
}

/*
      featured list css
  */
.featured__card--list {
  padding: 2rem;
  background: var(--color-background);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 1rem;
}
@media only screen and (max-width: 575px) {
  .featured__card--list {
    flex-direction: column;
  }
}
.featured__card--list__thumbnail {
  width: 100%;
}
@media only screen and (min-width: 576px) {
  .featured__card--list__thumbnail {
    width: 20rem;
  }
}
@media only screen and (min-width: 992px) {
  .featured__card--list__thumbnail {
    width: 21rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .featured__card--list__thumbnail {
    width: 28rem;
  }
}
.featured__card--list__thumbnail .media {
  line-height: 1;
}
.featured__card--list__content {
  width: 100%;
  padding: 2rem 0 0;
}
@media only screen and (min-width: 576px) {
  .featured__card--list__content {
    width: calc(100% - 20rem);
    padding: 0 0 0 2rem;
  }
}
@media only screen and (min-width: 992px) {
  .featured__card--list__content {
    width: calc(100% - 21rem);
    padding: 0 2rem 0 3rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .featured__card--list__content {
    width: calc(100% - 28rem);
  }
}
.featured__card--list:hover .featured__list--wishlist__btn.style2 {
  color: var(--color-hover);
  border-color: var(--color-hover);
}
.featured__card--list:hover .featured__thumbnail--img {
  transform: scale(1.1);
}

.featured__author--img__style2 {
  position: absolute;
  bottom: 2rem;
  left: 1rem;
}

.featured__info--list__style {
  padding: 2rem 0 1.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--color-border);
}

.featured__list--wishlist__btn.style2 {
  width: 3rem;
  height: 3rem;
  border: 1px solid var(--color-border);
  color: #e0e2e9;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 576px) {
  .featured__list--wishlist__btn.style2 {
    width: 4rem;
    height: 4rem;
  }
}

.featured__content--list__top {
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 576px) {
  .featured__content--list__top {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .featured__properties--footer.mt-50 {
    margin-top: 3rem;
  }
}

/*
      featured best selling css
  */
.featured__best--selling__section {
  background: #f8f7fc;
}

.featured__best--selling__inner {
  padding-bottom: 6rem;
}
@media only screen and (min-width: 768px) {
  .featured__best--selling__inner {
    padding-bottom: 7rem;
  }
}
@media only screen and (min-width: 992px) {
  .featured__best--selling__inner {
    padding-bottom: 8rem;
  }
}

.best__selling--advanced__text {
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 500;
  position: relative;
  margin-top: 2rem;
  padding-left: 1.5rem;
}
@media only screen and (min-width: 768px) {
  .best__selling--advanced__text {
    font-size: 2rem;
    line-height: 2.2rem;
    margin-top: 2.5rem;
  }
}
.best__selling--advanced__text::before {
  position: absolute;
  content: "";
  width: 0.7rem;
  height: 0.7rem;
  background: var(--color-hover);
  left: 0;
  top: 50%;
  transform: translatey(-50%);
}
.best__selling--advanced__text span {
  color: var(--color-hover);
}

.best__selling--download__btn {
  background: var(--color-background);
  color: var(--color-foreground);
  padding: 0 2rem;
}
.best__selling--download__btn svg {
  margin-left: 0.5rem;
}

.best__selling--thumbnail {
  width: 100%;
}
@media only screen and (min-width: 992px) {
  .best__selling--thumbnail {
    width: 80%;
  }
}
@media only screen and (min-width: 1200px) {
  .best__selling--thumbnail {
    width: 71%;
  }
}
.best__selling--thumbnail .swiper__nav--btn {
  bottom: 0;
  top: auto;
  transform: inherit;
  opacity: 1;
  visibility: visible;
  border-radius: 0;
  background: var(--color-background-2);
  color: var(--color-white);
  width: 4.5rem;
  height: 4rem;
}
@media only screen and (min-width: 576px) {
  .best__selling--thumbnail .swiper__nav--btn {
    width: 5.5rem;
    height: 5rem;
  }
}
.best__selling--thumbnail .swiper__nav--btn:hover {
  background: var(--color-hover);
}
.best__selling--thumbnail .swiper__nav--btn.swiper-button-next {
  left: 4.5rem;
  right: auto;
}
@media only screen and (min-width: 576px) {
  .best__selling--thumbnail .swiper__nav--btn.swiper-button-next {
    left: 5.5rem;
  }
}
.best__selling--thumbnail .swiper__nav--btn.swiper-button-disabled {
  background: var(--color-hover);
}

.best__selling--content {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--color-hover);
  padding: 4rem 3rem;
  z-index: 9;
}
@media only screen and (min-width: 992px) {
  .best__selling--content {
    width: 45rem;
  }
}
@media only screen and (max-width: 991px) {
  .best__selling--content {
    position: inherit;
    margin-top: 3rem;
  }
}

.best__selling--subtitle {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-white);
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 1200px) {
  .best__selling--subtitle {
    font-size: 1.5rem;
  }
}
.best__selling--subtitle svg {
  margin-right: 0.5rem;
}

@media only screen and (max-width: 1199px) {
  .best__selling--content__heading.mb-50 {
    margin-bottom: 4rem;
  }
}
@media only screen and (max-width: 991px) {
  .best__selling--content__heading.mb-50 {
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 991px) {
  .best__selling--info.mb-50 {
    margin-bottom: 3rem;
  }
}

.best__selling--title {
  font-size: 2.2rem;
  color: var(--color-white);
}
@media only screen and (min-width: 1200px) {
  .best__selling--title {
    font-size: 2.4rem;
  }
}

.best__selling--info__list {
  margin-bottom: 2.5rem;
}
@media only screen and (min-width: 1200px) {
  .best__selling--info__list {
    margin-bottom: 3.5rem;
  }
}
@media only screen and (max-width: 399px) {
  .best__selling--info__list {
    flex-direction: column;
    gap: 1.5rem;
  }
}
.best__selling--info__list:last-child {
  margin-bottom: 0;
}
.best__selling--info__text {
  font-weight: 500;
  color: var(--color-white);
}

.featured__column3 {
  padding-bottom: 7rem;
}

.featured-pagination4 .swiper-pagination-bullet {
  width: 1rem;
  height: 1rem;
}
.featured-pagination4 .swiper-pagination-bullet::before {
  left: -5px;
  top: -5px;
}

.featured__section--footer {
  margin-top: 3rem;
}
@media only screen and (min-width: 768px) {
  .featured__section--footer {
    margin-top: 4rem;
  }
}

.swiper-pagination-bullet {
  width: 25px !important;
  border-radius: 50px !important;
  background: #d52e32 !important;
}
.swiper-pagination-bullet:active { 
  background: #d52e32 !important;
}

.swiper-button-prev:after,.swiper-button-next:after {
  font-size: 20px !important;
  color: white;
}