.call__action--info__text span {
  display: block;
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--color-white);
  margin-bottom: 0.3rem;
}

.call__action--info__text a {
  font-size: 1.5rem;
  color: var(--color-white);
}

.call__action--info__text a:hover {
  color: var(--color-hover);
}

.call__action--icon {
  color: var(--color-hover);
}

.call__action--title {
  font-size: 2rem;
  font-weight: 600;
  color: white;
}

.call__action--inner {
  background: linear-gradient(90deg, #d52e32 0%, #911a1d 100%);
  padding: 2rem;
  border-radius: 1rem;
}

@media only screen and (max-width: 991px) {
  .call__action--inner {
    flex-direction: column;
    gap: 3rem;
  }
}

@media only screen and (max-width: 479px) {
  .call__action--inner {
    gap: 2.5rem;
    padding: 3rem 2rem;
    text-align: center;
  }
}

.call__action--info {
  gap: 1.2rem;
}

.call__action--container {
  padding: 1rem;
  background: var(--color-background);
  border: 1px solid var(--color-border);
  border-radius: 1rem;
}
@media (max-width: 991px) {
  .call__action--title {
    font-size: 1.4rem;
  }
}
