.blog__content--title {
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 1.5rem;
}

.blog__comment--title {
  font-size: 2.2rem;
  margin-bottom: 4rem;
}

@media only screen and (min-width: 768px) {
  .blog__content--title {
    font-size: 2.2rem;
    line-height: 3rem;
    margin-bottom: 2rem;
  }
}

@media only screen and (min-width: 992px) {
  .blog__content--title {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}

@media only screen and (min-width: 1200px) {
  .blog__content--title {
    font-size: 3rem;
    line-height: 4rem;
  }
}

@media only screen and (max-width: 767px) {
  .blog__details--thumbnail.mb-30 {
    margin-bottom: 2.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .blog__details--content__top.mb-40 {
    margin-bottom: 3rem;
  }
}

.blog__details--content__desc {
  color: var(--color-foreground-sub);
  line-height: 3rem;
}

@media only screen and (min-width: 1200px) {
  .blog__details--content__desc {
    font-size: 1.7rem;
    line-height: 3.2rem;
  }
}

.widget__step {
  padding: 3rem 2rem;
  border: 1px solid var(--color-border);
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

@media only screen and (min-width: 1200px) {
  .widget__step {
    padding: 3rem;
  }
}

.widget__step--title {
  font-size: 2rem;
  line-height: 2.2rem;
  padding-left: 1.5rem;
  border-left: 2px solid var(--color-hover);
  margin-bottom: 2rem;
}

.widget__featured--content {
  width: calc(100% - 9rem);
  padding-left: 1.5rem;
}

.write__your--comment {
  padding: 3rem 2rem;
  background: #f8f8f9;
  border-radius: 1rem;
}

@media only screen and (min-width: 768px) {
  .write__your--comment {
    padding: 4rem 3rem;
  }
}

.blog__comment--input input::-webkit-input-placeholder {
  color: #838383;
}

.blog__comment--input input::-moz-placeholder {
  color: #838383;
}

.blog__comment--input input:-ms-input-placeholder {
  color: #838383;
}

.blog__comment--input input:-moz-placeholder {
  color: #838383;
}

.blog__comment--input__field {
  width: 100%;
  height: 5rem;
  border: 1px solid #e5e5e5;
  background: var(--color-background);
  padding: 0 1.5rem;
  border-radius: 0.5rem;
}

@media only screen and (min-width: 768px) {
  .blog__comment--input__field {
    height: 5.5rem;
  }
}

.blog__comment--input__field:focus {
  border-color: var(--color-hover);
}

.blog__comment--textarea textarea::-webkit-input-placeholder {
  color: #838383;
}

.blog__comment--textarea textarea::-moz-placeholder {
  color: #838383;
}

.blog__comment--textarea textarea:-ms-input-placeholder {
  color: #838383;
}

.blog__comment--textarea textarea:-moz-placeholder {
  color: #838383;
}

.blog__comment--textarea__field {
  width: 100%;
  height: 15rem;
  border: 1px solid #e5e5e5;
  background: var(--color-background);
  padding: 1.5rem 1.8rem;
  border-radius: 0.5rem;
  resize: none;
}

.blog__comment--textarea__field:focus {
  border-color: var(--color-hover);
}

@media only screen and (min-width: 768px) {
  .blog__comment--textarea__field {
    height: 20rem;
  }
}

.widget__featured--title {
  font-size: 1.7rem;
  line-height: 2.4rem;
  margin-bottom: 1rem;
}

.widget__featured--date {
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #888686;
  font-weight: 600;
  margin-bottom: 1rem;
}

.widget__featured--date svg {
  margin-right: 0.7rem;
}

.widget__featured--items.d-flex {
  border-bottom: 1px solid #888686;
  padding-bottom: 0.125rem;
  /* 2px = 0.125rem */
  padding-top: 0.625rem;
  /* 10px = 0.625rem */
}

.__section {
  padding-bottom: 4rem;
}

@media only screen and (min-width: 786px) {
  .blog__section {
    padding-bottom: 6rem;
  }
}

@media only screen and (min-width: 1200px) {
  .blog__section {
    padding-bottom: 8rem;
  }
}

.blog__inner {
  padding: 0 1rem 2rem;
}

.blog__inner .swiper__nav--btn {
  top: 32%;
}

.blog__badge {
  position: absolute;
  bottom: 3px;
  left: 0;
  height: 3.5rem;
  line-height: 3.5rem;
  background: var(--color-hover);
  color: var(--color-white);
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 1.5rem;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
}

.blog__items:hover .blog__thumbnail--media {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.blog__thumbnail--media {
  border-radius: 0.5rem 0.5rem 0 0;
}

.blog__thumbblognail {
  line-height: 1;
}

.blog__thumbnail a {
  overflow: hidden;
  border-radius: 0.5rem 0.5rem 0 0;
}

.blog__content {
  /* box-shadow: -3px 5px 20px -6px rgba(0, 0, 0, 0.11); */
  padding: 2rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

@media only screen and (min-width: 1200px) {
  .blog__content {
    padding: 2rem 2.5rem;
  }
}

.blog__meta--icon {
  color: var(--color-hover);
}

.blog__meta--date {
  margin-left: 0.8rem;
  font-size: 1.4rem;
  color: var(--color-foreground-sub);
}

@media only screen and (min-width: 1200px) {
  .blog__meta--date {
    font-size: 1.5rem;
  }
}

.blog__meta--text {
  margin-left: 0.8rem;
  font-size: 1.4rem;
  color: var(--color-foreground-sub);
}

@media only screen and (min-width: 1200px) {
  .blog__meta--text {
    font-size: 1.5rem;
  }
}

.blog__meta--list {
  position: relative;
  margin-right: 1rem;
  padding-right: 2rem;
}

@media only screen and (min-width: 1200px) {
  .blog__meta--list {
    margin-right: 2rem;
    padding-right: 3rem;
  }
}

@media only screen and (max-width: 479px) {
  .blog__meta--list {
    margin-right: 0;
    padding-right: 0;
  }
}

.blog__meta--list:last-child {
  margin-right: 0;
  padding-right: 0;
}

.blog__meta--list:last-child::before {
  display: none;
}

.blog__meta--list::before {
  position: absolute;
  content: "";
  width: 1rem;
  height: 0.1rem;
  background: #676a7d;
  right: 0;
  top: 50%;
}

@media only screen and (max-width: 479px) {
  .blog__meta--list::before {
    display: none;
  }
}

.blog__meta {
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 479px) {
  .blog__meta {
    flex-wrap: wrap;
    gap: 1.5rem;
  }
}

.blog__title {
  font-size: 1.8rem;
  line-height: 2.8rem;
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 1200px) {
  .blog__title {
    font-size: 2.2rem;
    line-height: 3rem;
  }
}

.blog__desc {
  color: var(--color-foreground-sub);
  margin-bottom: 2rem;
}

.blog__link--btn {
  line-height: 2rem;
  font-weight: 700;
  font-size: 1.8rem;
}

.blog__link--btn:hover {
  text-decoration: underline;
}

.blog__link--btn svg {
  margin-left: 0.5rem;
  color: var(--color-hover);
}

.blog__widget--step {
  padding: 3rem 2rem;
  background: var(--color-background-3);
  border-radius: 1rem;
}

@media only screen and (min-width: 1200px) {
  .blog__widget--step {
    padding: 3rem;
  }
}

@media only screen and (min-width: 992px) {
  .widget__step--title {
    margin-bottom: 3rem;
  }
}

.widget__tag {
  flex-wrap: wrap;
  gap: 1.5rem;
}

li {
  list-style: none;
  line-height: 1;
}

.widget__tag--link {
  color: var(--color-foreground-sub);
  font-weight: 600;
  padding: 0.7rem 1rem;
  background: #3a23230d;
  border-radius: 0.5rem;
}
.widget__tag--link:hover {
  background: var(--color-hover);
  color: white;
}
.blog__items {
  background: #b1b1b138; /* border: 2px solid #fa4a4ac7; */
  /* height: 563px; */
  padding: 10px;
  border-radius: 24px 0px 61px 0px;
}

/* SwiperStyles.css */
.swiper-container {
  width: 100%;
  height: 50vh;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide .allitem {
  padding: 25px;
  border-radius: 0;
}

.swiper-slide .blog-allof {
  background: #fff;
  border-radius: 10px;
  margin-left: 30px !important;
}

.swiper-slide .discretion-blog {
  padding: 0;
  align-items: left;
}

.swiper-slide .discretion-blog p {
  font-size: 16px;
  color: #595665;
  padding-bottom: 15px !important;
  margin-top: 0;
  line-height: 26px;
  font-family: "DM Sans", Helvetica, Arial, Lucida, sans-serif;
}

.swiper-slide .discretion-blog h3 {
  color: #1f2d3d;
  font-size: 20px;
  font-weight: 700;
  margin-top: 25px !important;
  margin-bottom: 20px !important;
  line-height: 26px;
  font-family: "DM Sans", Helvetica, Arial, Lucida, sans-serif;
}

/* Swiper Pagination */
.swiper-pagination-bullet {
  background: #454343;
}

.swiper-pagination-bullet-active {
  background: #82b53f;
}
