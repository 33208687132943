.footer-widget {
  margin-bottom: 20px;
  text-align: left; /* Text left align */
}

.footer-title {
  font-size: 17px;
  margin-bottom: 19px;
  color: #d52e32;
  text-align: left;
}

.footer-menu ul {
  padding-left: 0; /* Remove default padding */
}

.footer-menu ul li {
  color: #ececec !important;
  font-size: 14px;
  margin-bottom: 27px;
  position: relative;
  list-style: none;
}

.footer-menu ul li a:hover {
  color: #fa4a4a;
}

.footer-address ul li {
  display: flex;
  font-size: 13px;
  color: white;
  margin-top: 8px;
  align-items: center;
}

.ltns__social-media ul {
  display: flex;
  justify-content: start; /* Align social media icons to left */
  gap: 10px;
}

.ltns__social-media ul li {
  width: 40px;
  height: 41px;
  padding: 9px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 35px;
  transition: 0.3s;
  color: floralwhite;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 576px) {
  .footer-widget {
    /* margin-bottom: 28px; */
    text-align: left;
  }
  .footer-title {
    font-size: 16px;
    margin-bottom: 13px;
    color: white;
    text-align: left; /* Default: Left for larger screens */
  }
  .footer-menu ul li {
    color: white;
    font-size: 12px;
    margin-bottom: 17px;
    position: relative;
    list-style: none;
  }
  .footer-address ul li {
    display: flex;
    font-size: 10px;
    color: white;
    margin-top: 8px;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .footer-title {
    font-size: 25px;
    margin-bottom: 19px;
    color: #d52e32;
    text-align: left;
  }
  .footer-menu ul li {
    font-size: 18px;
  }
}
