.approachimage {
  border-radius: 10px;
  margin-bottom: 42px;
}

.approachimage::before {
  position: absolute;
  content: "";
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: #fa4a4a;
  transform: skew(20deg);
  z-index: 1;
}

.about-thumb {
  width: 466px;
  border-radius: 10px;
  box-shadow: 10px 10px 5px rgba(181, 41, 42, 1);
}
.bi {
  font-size: 2.2rem;
}
.Chooseus {
  margin-top: 37px;
}
@media (max-width: 576px) {
  .Chooseus {
    margin-top: 0px;
    margin-left: 0;
    /*  */
  }
}
.profile-card {
  position: relative;
  font-family: sans-serif;
  width: 220px;
  height: 220px;
  background: #fff;
  padding: 30px;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
  margin: 0 25px;
}
.profile-card:hover {
  border-radius: 10px;
  height: 260px;
}
.profile-card .img {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.6s;
  z-index: 99;
}
.profile-card:hover .img {
  transform: translateY(-60px);
}
.img img {
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
}
.profile-card:hover img {
  border-radius: 10px;
}
.caption {
  text-align: center;
  transform: translateY(-80px);
  opacity: 0;
  transition: 0.6s;
}
.profile-card:hover .caption {
  opacity: 1;
}
.caption h3 {
  font-size: 21px;
  font-family: sans-serif;
}
.caption p {
  font-size: 15px;
  color: #0c52a1;
  font-family: sans-serif;
  margin: 2px 0 9px 0;
}
.caption .social-links a {
  color: #333;
  margin-right: 15px;
  font-size: 21px;
  transition: 0.6s;
}
.social-links a:hover {
  color: #0c52a1;
}
.content-box {
  background-color: rgba(251, 244, 244, 1);
  margin-top: 75px;
}

@media (max-width: 767px) {
  .content-box {
    margin-top: 20px;
  }
}
