:root {
  --color-background: #fff;
  --color-background-2: #26232a;
  --color-background-3: #f3f5fb;
  --color-background-4: #0b2c3d;
  --color-background-5: #edeef3;
  --color-background-6: #f5f5f5;
  --color-background-7: #f2f6f7;
  --color-background-8: #f8f7fc;
  --color-foreground: #26232a;
  --color-foreground-sub: #5e5f63;
  --color-border: #d9d9d9;
  --color-hover: #fa4a4a;
}

.color-offwhite {
  --color-foreground: #b4b4b4;
}
:root,
body,
html,
* {
  font-family: "Poppins", sans-serif !important;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  min-height: 100%;
  margin: 0;
  /* font-size: 62.5%; */
  padding: 0;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

body {
  font-weight: var(--font-body-weight);
  font-style: normal;
}

[data-aos="fade-up"] {
  transform: translate3d(0, 40px, 0);
  -webkit-transform: translate3d(0, 40px, 0);
  -moz-transform: translate3d(0, 40px, 0);
  -ms-transform: translate3d(0, 40px, 0);
  -o-transform: translate3d(0, 40px, 0);
}

a,
button {
  display: inline-block;
  cursor: pointer;
  transition: var(--transition);
  text-decoration: none;
  color: inherit;
}

a,
button,
img,
input,
textarea {
  font-size: 14px;
  transition: var(--transition);
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

span {
  display: inline-block;
  transition: var(--transition);
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.5rem;
  color: #d52e32;
  font-weight: 700;
  /* line-height: 1.2; */
  /* max-height: calc(2.5em); */
  /* font-size: 2.5 rem; */
  /* font-size: 2rem; */
}
@media (max-width: 992px) {
  h2 {
    font-size: 1.3rem;
    font-weight: 700;
  }
  h1 {
    font-size: 1.9rem !important;
    font-weight: 700;
  }
}
p {
  font-size: 14px;
  /* text-align: justify; */
}
@media (min-width: 1200px) {
  p {
    font-size: 17px;
  }
  h2 {
    font-size: 2rem;
    color: #d52e32;
  }
}
h1 {
  font-size: 3rem;
  font-weight: 700;
}
.swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  display: none;
}
@media (max-width: 768px) {
  p {
    text-align: justify !important;
  }
}
