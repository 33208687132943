.article-wrapper {
  display: flex;
  flex-direction: column;
  /* height: 100%;  */
  border: 1px solid #ddd;
  padding: 20px;
  /* background: #D52E32; */

  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}
.service-content p {
  font-size: 18px;
  /* line-height: 1.6; */
  color: #333;
}

.service-content h3 {
  /* color: #007bff; */
  margin-top: 36px;
  font-size: 30px;
  font-weight: 600;
}

.service-content ul {
  padding-left: 4px;
  list-style-type: none;
}

.service-content ul li {
  padding-bottom: 30px;
  font-size: 18px;
  position: relative;
}

.service-content ul li::before {
  content: "✔";
  color: #d52e32;
  font-weight: bold;
  margin-right: 8px;
}
