.hero__section--bg {
  position: relative;
  width: 100%;
  height: 70vh; /* Full-screen height */
  overflow: hidden;
}

.hero__section--bg video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  filter: brightness(55%);
}

.hero__section--inner {
  padding: 6rem 0 7rem;
}

@media only screen and (min-width: 480px) {
  .hero__section--inner {
    padding: 7rem 0 8rem;
  }
}

@media only screen and (min-width: 768px) {
  .hero__section--inner {
    padding: 10rem 0 12rem;
  }
}

@media only screen and (min-width: 992px) {
  .hero__section--inner {
    padding: 14rem 0;
  }
}

@media only screen and (min-width: 1366px) {
  .hero__section--inner {
    padding: 22rem 0;
  }
}

.hero__section--wrapper {
  max-width: 117rem;
  margin: 0 auto;
}

.hero__content {
  margin-bottom: 3rem;
}
.hero__content > h1 {
  font-size: 4.5rem;
}

.hero__content--desc {
  color: var(--color-white);
}

.hero__content--title {
  color: var(--color-white);
}
@media (max-width: 991px) {
  .hero__content > h1 {
    font-size: 2rem;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .hero__section--bg {
    height: 52vh;
  }
}
