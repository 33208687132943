@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: center;
  }
}
a.nav-link {
  font-size: 13px;
  margin-right: 3px;

  /* margin-left: 10px; */
  /* line-height: 2rem; */
  color: var(--color-background-2);
  font-weight: 600;
  /* padding: 0.3rem 0; */
}

.nav-item {
  /* padding-right: 10px; */
  position: relative;
}

.nav-item::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0.1rem;
  background: var(--color-hover);
  transition: width 0.3s ease;
  bottom: 0;
  left: 0;
}

.nav-item:hover::before {
  width: 100%;
}

a.nav-link:hover {
  text-decoration: none;
  color: var(--color-hover);
}
.nav-item .nav-link.active::before {
  width: 100%;
}
/* Header Icons Styling */
.header-icons {
  display: flex;
  align-items: center;
  position: relative;
  /* margin-top: 10px; */
}

/* Search Bar Container */
.search-bar-container {
  padding: 10px;
  position: absolute;
  top: 143%;
  left: -199px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .navbar.navbar-expand-lg {
    height: 83px;
  }
  .navbar-collapse {
    position: absolute;
    top: 66px;
    left: 14px;
    width: 100%;
    height: 100vh; /* Full screen height */
    background-color: white;
    padding: 10px;
    z-index: 999;
  }

  .footer__logo--img {
    height: 53px;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.btn.outline {
  background-color: transparent;
  padding: 15px;
  border: 2px solid #d52e32;
  color: #d52e32;
  transition: all 0.3s ease;
}
@media (max-width: 767px) {
  .navbar {
    padding: 0.5rem 1rem !important;
  }
  a.nav-link {
    font-size: 1rem;
    /* margin-left: 10px; */
    padding-bottom: 24px;
    padding-top: 23px;
    /* line-height: 2rem; */
    color: var(--color-background-2);
    font-weight: 500;
    /* padding: 0.3rem 0; */
  }
}
@media (min-width: 1400px) {
  a.nav-link {
    font-size: 1.3rem;
    /* margin-left: 10px; */
    /* line-height: 2rem; */
    margin-right: 10px;

    color: var(--color-background-2);
    font-weight: 500;
    padding: 0.3rem 0;
  }
}
