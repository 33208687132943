@import url("https://fonts.googleapis.com/css2?family=Piazzolla:ital,opsz,wght@0,8..30,200;0,8..30,400;0,8..30,900;1,8..30,300&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Ysabeau:wght@200;300;400;500;700&display=swap");

.companyWork {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  scroll-behavior: smooth;
}

/* !---Sction stratagies------------------------------------------- */
.companyWork {
  padding: 2rem 0;
  margin: 0rem 0 !important;
  background: rgba(0, 0, 0, 0.7) url("../../../..//src/assets/blog12.jpg")
    no-repeat center center;
  background-blend-mode: darken;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  backdrop-filter: blur(80%);
  -webkit-backdrop-filter: blur(80%);
}

.companyWork .text-content {
  color: white;
  text-align: center;
}

.projectBtn {
  color: #dbdbdb;
  font-weight: 450;
  padding: 0.25rem 2rem;
}

.projectBtn:hover {
  color: #000 !important;
  background-color: #dbdbdb;
}

/* !----End section 2nd-------------------------------------------- */

/* .project-Card-container {
  overflow-x: scroll;
  width: 100%;
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
} */

.project-Card-container {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  height: 370px;
  width: 268px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  /* margin: 1rem; */
  /* padding: 1.5rem 1.25rem 0.25rem 1.25rem; */
  padding: 12px;
  color: white;
  backdrop-filter: blur(10px); /* Apply the blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
}

/* .project-Card-container div:hover {
  box-shadow: 5px 5px 5px rgba(255, 69, 0, 0.5);
  border-radius: 20% 0;
  transition: 0.8s;
  cursor: pointer;
} */

/* .project-Card-container div:not(:hover) {
  border-radius: 0 20%;
  transition: 1s;
} */

.project-Card-container img {
  width: 100%;
  height: 130px;
  border-radius: 15px;
  object-fit: cover;
  /* margin: auto; */
}

.project-Card-container p {
  /* padding: 5px; */
  text-align: start;
  font-size: 1rem;
  text-wrap: wrap;
  color: rgba(255, 255, 255, 0.74);
}

.project-Card-container a {
  color: #212529;
}

.project-Card-container a:hover {
  color: #fff;
}

/* !----End Section company work-------------------------------------------- */

.latest-work {
  margin: 30px 0 0 0;
  text-align: center;
}

.work-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.work-container div {
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  width: 350px;
  height: 250px;
  margin: 15px;
  flex: 1 1 300px;
}

.work-container div:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  width: 350px;
  height: 250px;
  margin: 15px;
}

.work-container div img {
  width: 100%;
  height: 100%;
}

/* !-------------------------------------------------------------------Footer---------- */

#About ::-webkit-scrollbar {
  display: none;
}

@media (max-width: 992px) {
  .project-Card-container {
    height: 301px;
    width: 177px;
  }
  .project-Card-container h6 {
    font-size: 14px;
  }
  .project-Card-container P {
    font-size: 10px;
    color: white;
  }
  .project-Card-container button {
    font-size: 10px;
  }
  .project-Card-container img {
    height: 80px;
  }
}
