.service-section {
  background: url("../../../assets/wp3.jpg");
  background-attachment: fixed;
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  scroll-behavior: smooth;
  text-transform: capitalize;
  width: 100%;

  /* Apply a slight dark overlay */
  position: relative;
}

.service-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.11); /* Black overlay with 40% opacity */
  z-index: 1;
}

/* import bloBG from "../../assets/advisory_bg.jpg"; */
.amenities__icone {
  margin-bottom: 1rem;
}

.amenities__icone span {
  width: 5rem;
  height: 5rem;
  background: #d52e32;
  color: white;
  line-height: 5rem;
  text-align: center;
  box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

@media only screen and (min-width: 992px) {
  .amenities__icone span {
    width: 7rem;
    height: 7rem;
    line-height: 7rem;
  }
}

@media only screen and (max-width: 991px) {
  .amenities__icone span svg {
    width: 3rem;
    height: 2.8rem;
  }
}
.expertise-card {
  background: black;
  /* width: 298px;
  height: 454px; */
}
