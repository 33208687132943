/* Dark overlay effect */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.3s ease-in-out;
  z-index: 1;
}

/* Dark overlay on hover */
.overlay.active {
  background: rgba(0, 0, 0, 0.5);
}

/* Ensure text is above the overlay */
.title-container {
  bottom: 10px;
  transform: translate(-50%, 0);
  transition: all 0.4s ease-in-out;
  z-index: 2;
  opacity: 1;
}

/* Move title to center on hover */
.center-content {
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Darken image on hover */
.card-image {
  filter: blur(5px); /* Blur Effect */
  transition: filter 0.3s ease-in-out;
}

.real-estate-card:hover .card-image {
  filter: blur(2px); /* Reduce Blur on Hover */
}

.card-image {
  transition: filter 0.3s ease-in-out;
}
.darken-image {
  filter: brightness(0.4);
}
