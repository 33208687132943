/* Background section styling */
.background-section {
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: darken;
  background-position: center center;
  background-size: cover;
  height: 60vh;
}
@media (max-width: 992px) {
  .background-section {
    height: 30vh;
  }
}
